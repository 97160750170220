<template>
  <div id="data-list-list-view" class="data-list-container">
         <feather-icon icon="ArrowLeftIcon" class="mb-4" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Hospital')"/>

    <div>
      <hospital-Tab
                  class="mt-4"
                  :mainTabName="'HospitalSpecialities'"
                />
      <vx-card class="flex-1" v-bind:title="$t('Hospital Specialty')">
           <vs-button class="mb-5 ml-5"   color="success" @click="isAddNew=true">{{
            $t("Add New Specialty")
          }}</vs-button>
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="HospitalSpecialities"
        >
          <template slot="thead">
            <vs-th> {{ $t("Specialty Name") }}</vs-th>
            <vs-th> {{ $t("Is Parent") }}</vs-th>
            <!-- <vs-th> {{ $t("Activation") }}</vs-th> -->
            <vs-th> {{ $t("Action") }}</vs-th>

          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">


                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.Specialty.Name }}
                  </p>
                </vs-td>
                 <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.Specialty.ParentSpecialtyID>0">
                    False
                  </p>
                  <p class="product-name font-medium truncate" v-else>
                    True
                  </p>
                </vs-td>
              <!-- <vs-td>
                <vs-checkbox @change="changeStatus(tr)" v-model="tr.IsActive"></vs-checkbox>
                </vs-td> -->
                  <vs-td class="whitespace-no-wrap">
                    <feather-icon
                          icon="TrashIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                            @click="openConfirm(tr)"
                      />
                  <!-- <vs-button
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  > -->
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" :to="{ name: 'Hospital' }">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div>

      <vs-popup  :active.sync="isAddNew">
              <vs-card>
                 <div class="vx-row m-5">
                <vs-radio
                @change="parentSpeciltyModel={}"
                  v-model="isAddParent"
                  :vs-value="true"
                  class="mr-4"
                  vs-name="isAddParent"
                  >Maim Spaicilty</vs-radio
                >
                <vs-radio
                @change="parentSpeciltyModel={}"
                  v-model="isAddParent"
                  :vs-value="false"
                  class="mr-4"
                  vs-name="isAddParent"
                  >Sup Spaicilty</vs-radio
                >
              </div>
                <div class="vx-col w-full" v-if="isAddParent">
        <p class="vs-input--label"> {{ $t('Parent Specialty') }}</p>
        <v-select
          label="Name"
          name="ParentSpecialty"
          class="mw-full"
          v-model="parentSpeciltyModel.ParentSpecialtyID"
          :options="parentSpecialties"
          :reduce="(ID) => ID.ID"
        />
      </div>
         <div class="vx-col w-full" v-if="!isAddParent">
        <p class="vs-input--label"> {{ $t('Hospital Main Specialty') }}</p>
        <v-select
          label="SpecialtyName"
          name="Hospital Main Specialty"
          class="mw-full"
          v-model="parentSpeciltyModel.HospitalSpecialtyModel"
          :options="ParentHospitalSpecialities"
          :reduce="(ID) => ID"
          @input="ChangeParentSpeciality(parentSpeciltyModel.HospitalSpecialtyModel)"
        />
      </div>
       <div class="vx-col w-full mt-5" v-if="parentSpeciltyModel.HospitalSpecialtyModel">
        <p class="vs-input--label"> {{ $t('Chiled Specilty') }}</p>
        <v-select
          label="Name"
          name="ChiledSpecialty"
          class="mw-full"
          v-model="parentSpeciltyModel.HospitalChiledSpecialtyID"
          :options="childSpecialties"
          :reduce="(ID) => ID.ID"
        />
      </div>
                 <vs-checkbox class="mt-5 " v-model="parentSpeciltyModel.IsActive">{{ $t("Is Active")}} </vs-checkbox>

      <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-center">
          <vs-button :disabled="!(parentSpeciltyModel.ParentSpecialtyID||parentSpeciltyModel.HospitalChiledSpecialtyID)" type="border" color="success" @click="AddSpecialty">{{
            $t("Add")
          }}</vs-button>
        </div>
      </div>
    </div>

                </vs-card>
       </vs-popup>

  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import hospitalTab from "@/views/tabs/hospitalTab.vue";

export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    hospitalTab

    // vSelect,
  },

  computed: {
    HospitalDoctors() {
      return this.$store.state.HospitalList.HospitalDoctors;
    },
       specialities() {
      return this.$store.state.HospitalList.specialities;
    },
    HospitalSpecialities() {
      return this.$store.state.HospitalList.HospitalSpecialities;
    },
    ParentHospitalSpecialities() {
      return this.$store.state.HospitalList.HospitalSpecialities.filter(
        (ob) => ob.Specialty.ParentSpecialtyID == null
      );
    },
     parentSpecialties() {
      return this.$store.state.SpecialtyList.parentSpecialties.filter(
       (ob) => this.HospitalSpecialities.findIndex(element => element.Specialty.ID == ob.ID) == -1
      );
    },
     childSpecialties() {
      return this.$store.state.SpecialtyList.childSpecialties.filter(
       (ob) => this.HospitalSpecialities.findIndex(element => element.Specialty.ID == ob.ID) == -1
      );
    },
  },
  data() {
    return {
    search: {},
    isAddNew:false,
    isAddParent:true,
    parentSpeciltyModel:{},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,

        years: []
      },

      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},

      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile"
    };
  },
  methods: {
    AddSpecialty(){
      debugger
         var model = {};
       model.IsActive = this.parentSpeciltyModel.IsActive;
       model.HospitalID = this.$route.params.ID;
       if(this.isAddParent==true){ //add parent
       model.SpecialtyID = this.parentSpeciltyModel.ParentSpecialtyID;
       }
       else{
       model.SpecialtyID = this.parentSpeciltyModel.HospitalChiledSpecialtyID;
       }
        this.$vs.loading();
      this.$store.dispatch("HospitalList/AddHospitalSpecialty",model).then(()=>{
          this.$vs.loading.close();
          window.showSuccess();
          this.parentSpeciltyModel = {};
          this.isAddNew = false;
           var object={};
        object.HospitalID=this.$route.params.ID;

      this.$store.dispatch("HospitalList/GetAllSpecialties", object);
      });
    },
     ChangeParentSpeciality(item) {
         this.$vs.loading();
      this.$store.dispatch("SpecialtyList/GetSpecialtyByParentSpecialtyID",item.Specialty.ID).then(()=>{
          this.$vs.loading.close();
      });
    },
   changeStatus(item){
          this.$vs.loading();
     this.$store
        .dispatch("HospitalList/UpdateHospitalSpecialty",item)
        .then(() => {
          window.showSuccess();
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
  },
  openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
  acceptAlert() {
     this.$store.dispatch("HospitalList/DeleteHospitalSpecialty", this.rowDataForDelete).then(()=>{
      //  this.GetHospitalDoctors(this.$route.params.ID);
       var object={};
        object.HospitalID=this.$route.params.ID;

      this.$store.dispatch("HospitalList/GetAllSpecialties", object);
      window.showDeleteSuccess();

     });
    },


  },
  created() {
    if (!moduleSpecialty.isRegistered) {
      debugger;
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
   // this.GetSurgery();
 this.$store.dispatch("SpecialtyList/GetParentSpecialty");
    const ID = this.$route.params.ID;
    if (ID != undefined) {

      // this.GetHospitalDoctors(ID);
      var object={};
        object.HospitalID=ID;

      this.$store.dispatch("HospitalList/GetAllSpecialties", object);
    }

  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
